import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gallery/kindrid",
  "project": "Kindrid",
  "projectID": "kindrid",
  "projectURL": "https://kindrid.com",
  "projectDate": "2020-03-21"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const GalleryImg = makeShortcode("GalleryImg");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, nesciunt illum quos id quo repellat dolorum totam sed voluptatem, numquam harum accusamus delectus, soluta laborum? Minima libero atque rerum deserunt? */
    }
    <Gallery mdxType="Gallery">
  <GalleryImg src="kindrid_home.png" alt="Home page screenshot" title="Home" mdxType="GalleryImg" />
  <GalleryImg src="kindrid_block-see-how-hover.png" alt="See How block screenshot" title="See How block" mdxType="GalleryImg" />
  <GalleryImg src="kindrid_pricing.png" alt="Pricing page screenshot" title="Pricing" mdxType="GalleryImg" />
  <GalleryImg src="kindrid_compare.png" alt="Compare page screenshot" title="Compare" mdxType="GalleryImg" />
  <GalleryImg src="kindrid_join.png" alt="Join page screenshot" title="Join" mdxType="GalleryImg" />
  <GalleryImg src="kindrid_ministryone.png" alt="MinistryOne page screenshot" title="MinistryOne" mdxType="GalleryImg" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      